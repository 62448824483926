.outerHeaderWrapper {
  position: fixed !important;
  background-color: #fff;
  width: 100%;
  z-index: 2;
}
.headerImg {
  width: 100%;
  display: none;
  padding-top: 10em;
}
.header-wrapper {
  box-shadow: 0 1px 25px 1px #000 !important;
  display: flex;
  align-items: center;
}
.header-wrapper .item {
  color: #000 !important;
}
.tools-search-wrapper {
  display: none !important;
}
.header-wrapper .ui.secondary.pointing.menu .active.item {
  border-color: rgba(158, 192, 239, 0.7);
}
.header-wrapper .ui.secondary.pointing.menu .active.item:hover {
  border-color: #9EC0EF;
}
.hamburger-inner,
.hamburger-inner::before,
.hamburger-inner::after {
  background-color: #9EC0EF !important;
}
#page-document {
  padding-top: 8em;
}
.view-editview .outerHeaderWrapper {
  position: unset !important;
}
@media only screen and (min-width: 990px) {
  .headerImg {
    display: block;
  }
  #page-document {
    padding-top: unset;
  }
}
