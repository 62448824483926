.newsContainer {
  margin: 2.5em 0;
}
.gridItem {
  transition: transform 200ms;
}
.gridItem:hover {
  transform: scale(1.04);
}
.newsBox {
  height: 35em;
  width: 20em;
  box-shadow: 0 5px 15px 5px rgba(0, 0, 0, 0.37);
  -webkit-box-shadow: 0 5px 15px 5px rgba(0, 0, 0, 0.37);
  -moz-box-shadow: 0 5px 15px 5px rgba(0, 0, 0, 0.37);
}
.newsTop {
  height: 60%;
  background-color: white;
  display: flex;
  align-items: flex-end;
  position: relative;
}
.newsImg {
  position: absolute;
  z-index: 0;
  width: 280px;
  height: 294px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.newsBottom {
  height: 40%;
  background-color: #9EC0EF;
  color: #000;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1.5em 0 0 0;
}
.newsBottom p {
  font-size: 1.1em;
}
.newsTitle {
  font-size: 1.3em !important;
  padding: 0 0.5em 0 0.5em;
  text-align: center;
}
/********** remove Event component date box **********/
.event-view > .grid > div:nth-child(2) {
  display: none !important;
}
.event-view .ui.grid > .row > [class*="seven wide"].column,
.ui.grid > .column.row > [class*="seven wide"].column,
.ui.grid > [class*="seven wide"].column,
.ui.column.grid > [class*="seven wide"].column {
  width: 100% !important;
}
.event-view > .grid > div:nth-child(3) > div:nth-child(2) {
  display: none !important;
}
