#footer {
  background-color: #171D2A;
  border: unset;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-bottom: 0;
  height: 26em;
}
#footer hr {
  width: 80%;
}
.footerTop {
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
  width: 100%;
  flex-wrap: wrap;
  align-content: center;
}
.footerTop > div {
  margin: 0.5em 0;
}
.logoWhiteDiv {
  display: flex;
  justify-content: center;
}
.logoWhite {
  width: 6em;
  height: -moz-fit-content;
  height: fit-content;
}
.footerBottom {
  width: 100%;
  display: flex;
  justify-content: center;
}
.footerBottom .footerList {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.footerBottom .footerList .item {
  padding: unset;
  margin: 0.4em 0;
}
.footerList .item {
  color: #fff;
}
.footerList .item i {
  margin: 0 0.3em 0 0;
  font-size: 14pt;
}
.footerList .item i.ri-copyright-line {
  margin-right: 0.1em;
}
@media only screen and (min-width: 600px) {
  #footer {
    height: 13em;
  }
  .footerTop {
    flex-direction: row;
    flex-wrap: unset;
    align-content: unset;
    width: 50%;
  }
  .footerList {
    margin: unset;
  }
  .footerBottom .footerList {
    flex-direction: row;
  }
  .footerBottom .footerList .item {
    margin: 0 0.5em;
  }
}
