.filter-button-group,
hr {
  margin: 1em;
}
.filterBtn {
  border-radius: 8px;
  margin: 0.4em 0.5em;
  cursor: pointer;
}
.filterBtn:hover {
  background-color: #9EC0EF !important;
}
.is-checked {
  background-color: #9EC0EF !important;
}
.gridIsotope {
  margin-top: 5% !important;
  margin-bottom: 5%;
  margin-right: unset;
  margin-left: unset !important;
  min-height: 50em;
}
.grid-item {
  display: flex;
  flex-direction: column !important;
  width: -moz-fit-content;
  width: fit-content;
  margin: 0 1em;
  margin-bottom: 5em;
}
.gridImg {
  border: 2px solid #d3d3d3;
  border-bottom: 1px;
  width: 20em;
  height: 22em;
  -o-object-fit: cover;
     object-fit: cover;
}
.gridContent {
  width: 100%;
  height: 100%;
  min-height: 19em;
  width: 20em;
  border: 2px solid #d3d3d3;
  padding: 1em 1em;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  word-break: break-word;
}
.gridContent p,
.gridContent a {
  width: -moz-fit-content;
  width: fit-content;
  margin-right: 0;
}
.gridContentNameDiv {
  display: flex;
  justify-content: center;
  margin-bottom: 0.5em;
}
.gridContentName {
  font-weight: bold;
  font-size: 1.2em;
}
.stackWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.stackWrapper div:nth-child(2) {
  align-items: center;
  margin: 2em 0 0 0;
}
.stackWrapper div p {
  word-break: normal;
  min-height: 20px;
}
.stackWrapper div:first-child p {
  padding-right: 0.3em;
}
.gridContentTagDiv {
  display: flex;
  justify-content: center;
  padding: 0.5em;
}
.noContent {
  opacity: 0;
}
.gridContentTag {
  border-radius: 50px;
}
.gridContentTag,
.filterBtn {
  background-color: transparent;
  color: #000000;
  border: 2px solid #9EC0EF;
  padding: 0.4em 1em;
}
.gridContent a i {
  font-size: 2em;
  color: #000000;
}
.gridContent a i:hover {
  color: rgba(158, 192, 239, 0.7);
}
.mailplaceholder {
  height: 24px;
  margin-top: 16px;
}
.gridContentTagContainer {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
@media only screen and (max-width: 600px) {
  .lightgalleryUl {
    -moz-column-count: 2 !important;
         column-count: 2 !important;
  }
}
@media only screen and (max-width: 642px) {
  .gridIsotope {
    display: flex !important;
    flex-direction: column !important;
    align-items: center;
  }
  .grid-item {
    left: unset !important;
  }
}
